import { NgModule } from '@angular/core';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { AvatarModule } from 'primeng/avatar';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { ImageModule } from 'primeng/image';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GalleriaModule } from 'primeng/galleria';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChipModule } from 'primeng/chip';
import { PanelMenuModule } from 'primeng/panelmenu';
import { InputTextModule } from 'primeng/inputtext';
import { DropdownModule } from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import { PaginatorModule } from 'primeng/paginator';
import { FileUploaderComponent } from './file-uploader/file-uploader.component';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {DialogModule} from 'primeng/dialog';

const PrimengComponents = [
  CascadeSelectModule,
  AvatarModule,
  FileUploadModule,
  ToastModule,
  ImageModule,
  CardModule,
  ChipModule,
  GalleriaModule,
  ProgressBarModule,
  ConfirmDialogModule,
  PanelMenuModule,
  InputTextModule,
  DropdownModule,
  PaginatorModule ,
  InputSwitchModule,
  AccordionModule,
  MultiSelectModule,
  InputTextareaModule,
  DialogModule
];
@NgModule({
  imports: [PrimengComponents],
  exports: [PrimengComponents, FileUploaderComponent],
  providers: [ConfirmationService, MessageService],
  declarations: [
    FileUploaderComponent
  ],
})
export class PrimengModule {}
